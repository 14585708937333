var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"xQ6q6Woa2p0uf4AbzgxxY"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://725fd7a8a53d4578a84e6a1dc8d65a90@o1379274.ingest.sentry.io/6692116',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.01,
  environment: process.env.NEXT_PUBLIC_ENV,

  // session replay properties
  replaysSessionSampleRate: 0.001,
  replaysOnErrorSampleRate: 0.05,

  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true
    })
  ]
});
